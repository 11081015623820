// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

// GRAVITY FORMS
.gform_wrapper {
  margin: $grid-gutter-width*1.5 0;
  .gform_heading {
    @extend .sr-only;
  }
  ul.gform_fields {
    li.gfield {

      .gfield_label {
        font-size: $font-size-sm;
        color: $brand-primary;
        margin-bottom: 0;
        .gfield_required {
          color: $brand-primary;
          font-size: $font-size-xs;
          font-weight: $font-weight-base;
        }
      }
      .gfield_description,.gfield_consent_label {
        font-size: $font-size-sm;
        color: $brand-primary;
        letter-spacing: .02em;
      }
      .gfield_description {
        padding-top: 5px;
      }
      .ginput_container {
        margin-top: $grid-gutter-width*.25;
        input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),
        textarea, select {
          @extend .form-control;
        }
        &.ginput_container_consent {
          padding-left: $grid-gutter-width*1.5;
          padding-top: $grid-gutter-width/2;
          position: relative;
          input[type=checkbox],input[type=radio] {
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
      &.gfield_error {
        background-color: transparent;
        margin-bottom: 0 !important;
        border: none;
        padding-top: 0;
        padding-bottom: 0;
        .gfield_label {
          color: $red;
          .gfield_required {
            color: $red;
          }
        }
        .ginput_container {
          margin-top: $grid-gutter-width*.25 !important;
        }
        .validation_message {
          color: $red;
          font-size: $font-size-xs;
          font-weight: $font-weight-base;
          padding-bottom: 0;
          padding-right: 0;
          text-align: right;
        }
      }
    }
  }
  .gform_footer {
    padding-top: $grid-gutter-width/2;
    input.button,input[type=submit] {
      @extend .btn;
      text-transform: none;
    }
  }
  div.validation_error {
    @extend .bg-yellow-25;
    color: $red;
    border: 1px solid rgba($red,.5);
    border-radius: $grid-gutter-width/4;
    max-width: calc(100% - 16px)!important;
    padding: $grid-gutter-width;
    font-size: $font-size-sm;
  }
}
.gform_confirmation_wrapper {
  @extend .bg-blue-gradient;
  @extend .sans-light;
  font-size: $font-size-h2;
  padding: $grid-gutter-width*2;
  .gform_confirmation_message {
    @extend .border-left-gradient;
  }

}
