aside.sidebar {
  .sidebar-wrap {
    @extend .bg-yellow-50;
    padding: $grid-gutter-width*1.5 $grid-gutter-width;
  }
  .widget {
    &.recent-posts-extended {
      &>h3 {
        @extend .title-decoration;
      }
      .rpwe-block {
        ul {
          @extend .list-normalize;
          margin-bottom: 0;
          .single-story &,.page-id-95 & {
            margin-bottom: $grid-gutter-width;
          }
          @include media-breakpoint-between(sm, md) {
            margin-left: -$grid-gutter-width/2 !important;
            display: -ms-flexbox;
            display: flex;
            flex: 1;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            margin-right: 0 !important;
            margin-left: 0 !important;
          }
          li {
            border-bottom: none;
            margin: 0 0 $grid-gutter-width*1.6 !important;
            .single-story &,.page-id-95 & {
              margin-bottom: $grid-gutter-width*.8 !important;
            }
            @include media-breakpoint-between(sm, md) {
              flex: 0 0 50%;
              max-width: 50%;
              padding-right: 10px;
              padding-left: 10px;
              position: relative;
              width: 100%;
              min-height: 1px;
            }
            a {
              display: inline-block !important;
              &.rpwe-img {
                @extend .border-link-img;
              }
            }
            h3.rpwe-title {
              font-size: $font-size-h3 !important;
              line-height: 1.2;
              margin-bottom: $grid-gutter-width*.75 !important;
              a {
                color: $blue;
                @include hover-focus {
                  color: $black;
                }
              }
            }
            .btn {
              margin-bottom: $grid-gutter-width/2;
            }
            .single-post &, .single-conversation &,.page-id-93 & {
              @extend .border-left-gradient;
              h3.rpwe-title {
                a {
                  @extend .gradient-text-link;
                }
              }
            }
            .single-story &,.page-id-95 & {
              h3.rpwe-title {
                @extend .sans-light;
                margin-left: 8px;
                margin-right: 8px;
                a {
                  text-decoration: none;
                  color: $blue-light;
                  @include hover-focus {
                    color: $brand-primary;
                  }
                }
              }
              .btn {
                margin-left: 8px;
              }
            }
          }
        }
      }
    }
  }
  .viewall {
    text-align: center;
    @include media-breakpoint-between(sm, md) {
      text-align: left;
    }
    .btn-gradient {
      width: 100%;
      max-width: 250px;
      margin: 0 auto;
    }
  }
}
