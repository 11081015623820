.btn {
  cursor: pointer;
  border: none;
  border-radius: 0;
  font: $btn-font-weight $btn-font-size $btn-font-family;
  box-shadow: $btn-box-shadow;
  color: $btn-default-color;
  background-color: $btn-default-bg;
  text-decoration: none;
  text-transform: lowercase;
  padding: $btn-padding-y $btn-padding-x;
  letter-spacing: .02em;
  @include hover-focus {
    color: $btn-default-color-hover;
    background-color: $btn-default-bg-hover;
  }
  &.btn-primary {
    color: $btn-primary-color;
    background-color: $btn-primary-bg;
    @include hover-focus {
      color: $btn-primary-color-hover;
      background-color: $btn-primary-bg-hover;
    }
  }
  &.btn-gradient {
    color: $blue;
    background-color: $white;
    font-size: $font-size-xs;
    border: 3px solid;
    border-image-source: linear-gradient(75deg, $gradient-light-start, $gradient-light-end);
    border-image-slice: 1;
    @include hover-focus {
      background-color: $yellow;
//       background-color: rgba($blue-light,.2);
    }
  }
}


