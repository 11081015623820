html {
  font-size: 18px;
  letter-spacing: .03em;
}
body {
  line-height: $line-base;
}
a {
  @include transition($link-transition);
}
h1 {
  @include media-breakpoint-down(sm) {
    font-size: $font-size-h2;
  }
}
h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
  letter-spacing: -.01em;
}
p,ol,ul,blockquote,.wp-block-image {
  margin-bottom: $grid-gutter-width;
}
.block {
  padding: $grid-gutter-width*3.5 0;
  &.block-question {
    @extend .bg-yellow-25;
  }
  &.block-question, &.block-story-submission {
    text-align: center;
    h1 {
      margin: 0 0 $grid-gutter-width*1.5;
      a {
        color: $blue;
        text-decoration: none;
        @include hover-focus {
          color: $blue-light;
//           text-decoration: underline;
        }
      }
    }
  }
}
.strapline {
  @extend .title-decoration;
}
.imgLink {
  display: inline-block;
  @extend .border-link-img;
}
.page-header {
  @extend .bg-yellow-20;
  padding: $grid-gutter-width*3 0 $grid-gutter-width*2;
  @include media-breakpoint-up(md) {
    padding: $grid-gutter-width*4 0 $grid-gutter-width*3;
  }
  .strapline {
    margin-bottom: $grid-gutter-width*1.5;
  }

  .post-type-archive-story & {
    h1 {
      @extend .sans-light;
      font-size: $font-size-h3;
      margin-bottom: $grid-gutter-width*.75;
      a {
        text-decoration: none;
      }
    }
  }
  .blog & {
    article {
      @extend .border-left-gradient;
      height: 100%;
/*
      @include media-breakpoint-down(md) {
        height: auto;
      }
*/
      h1 {
        margin-bottom: $grid-gutter-width;
        a {
          @extend .gradient-text-link;
        }
        @include media-breakpoint-down(md) {
          font-size: $font-size-h2;
        }

      }
    }
  }
  .page-id-93 &, .page-id-95 & {
    @extend .bg-blue-gradient;
  }
  .single-conversation &,.post-type-archive-conversation & {
    @extend .bg-blue-gradient;
    .strapline {
      color: $yellow;
      background-image: url(../images/title-decoration-light.png);
    }
    article {
      @extend .border-left-gradient;
      h1 {
        margin-bottom: $grid-gutter-width;
        a {
          color: $white;
          text-decoration: none;
          @include hover-focus {
            color: $yellow;
          }
        }
      }
      .btn {
        margin: $grid-gutter-width/2 0;
      }
    }
  }
  .single-story & {
    background-color: transparent;
    padding: $grid-gutter-width 0 0;
  }
  section {
    @extend .border-left-gradient;
    @include media-breakpoint-up(md) {
      padding-left: $grid-gutter-width*1.5;
    }
  }
  .featured-img {
    max-width: 100%;
  }
}
.wrap {
  margin-top: $grid-gutter-width*2;
  margin-bottom: $grid-gutter-width*1.5;
  &>.row {
    align-items: flex-start;
  }
  .blog &,.post-type-archive & {
    @include media-breakpoint-up(md) {
      margin-top: $grid-gutter-width*3;
    }
  }
}

.modal {
  .modal-dialog {
    @include media-breakpoint-up(md) {
      max-width: 600px;
    }
    @include media-breakpoint-up(lg) {
      max-width: 720px;
    }
    .modal-content {
      font-size: $font-size-sm;
      border: 2px solid $blue-med;
      .modal-header {
        background-color: $blue-med;
        color: $white;
        h5 {
          font: $font-weight-med $font-size-md $font-family-base;
        }
        .close {
          color: $white;
          text-shadow: 0 1px 0 $black;
          opacity: .8;
        }
      }
      .modal-body {
        max-height: 400px;
        overflow-y: scroll;
      }
    }
  }
}

/* MOBILE GUTTERS */
@include media-breakpoint-down(xs) {
  .page-header &>.container,
  .wrap.container main {
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
  }
  .wrap.container aside.sidebar {
    padding-left: 0;
    padding-right: 0;
  }
  .block-home,.hero-home {
    padding-left: $grid-gutter-width/2;
    padding-right: $grid-gutter-width/2;
  }
}
@include media-breakpoint-down(xs) {
  .wrap.container aside.sidebar {
    padding-left: 0;
    padding-right: 0;
  }
}
