// Grid system
.main {
  @include make-col-ready();
  @include make-col(12);
  @include make-col-offset(0);
  body:not(.single,.page-id-93,.page-id-95,.page-template-template-wide) & {
    @include media-breakpoint-up(md) {
      @include make-col(10);
      @include make-col-offset(1);
    }
    @include media-breakpoint-up(xl) {
      @include make-col(8);
      @include make-col-offset(2);
    }
  }
  .single &,.page-id-93 &,.page-id-95 & {
    @include media-breakpoint-up(lg) {
      @include make-col(7);
    }
    @include media-breakpoint-up(xl) {
      @include make-col(6);
      @include make-col-offset(1);
    }
  }
  .page-template-template-wide & {
    @include media-breakpoint-up(md) {
      @include make-col(12);
      @include make-col-offset(0);
    }
    @include media-breakpoint-up(xl) {
      @include make-col(12);
      @include make-col-offset(0);
    }
  }
}
.sidebar {
  @include make-col-ready();
  @include make-col(12);
  @include make-col-offset(0);
  @include media-breakpoint-up(lg) {
    @include make-col(4);
    @include make-col-offset(1);
  }
  @include media-breakpoint-up(xl) {
    @include make-col(3);
  }
}
/*
.main {
  @include make-col-ready();
  @include media-breakpoint-up(lg) {
    @include make-col($main-lg-columns);
    .sidebar-primary & {
      @include make-col($main-lg-columns - $sidebar-lg-columns);
    }
  }
}
.sidebar {
  @include make-col-ready();
  @include media-breakpoint-up(lg) {
    @include make-col($sidebar-lg-columns);
  }
}
*/
