header.banner {
  section {
    padding: $grid-gutter-width 0;
    body:not(.home) & {
      @include gradient-y($gradient-head-start, $gradient-head-end, 0%, 100%);
    }
  }
  .home & {
    position: absolute;
    width: 100%;
  }
  .row {
    align-items: center;
  }
  .brand {
    width: 100px;
    display: block;
    @include media-breakpoint-down(sm) {
      margin: 0 auto;
    }
    img {
      max-width: 100%;
    }
  }
  .navbar-toggler {
    float: right;
    color: $white;
    font-size: $font-size-lg;
  }
  .nav-primary {
    .nav {
      justify-content: flex-end;
      .nav-item {
        .nav-link {
          text-transform: uppercase;
          text-decoration: none;
          font: $font-weight-bold $font-size-sm $font-family-sans;
          color: $white;
          padding-left: $grid-gutter-width;
          padding-right: $grid-gutter-width;
          @include media-breakpoint-up(lg) {
            padding-left: $grid-gutter-width*1.5;
            padding-right: $grid-gutter-width*1.5;
          }
          @include hover-focus {
            color: $brand-secondary;
          }
        }
        &.active {
          .nav-link {
            color: $brand-secondary;
          }
        }
      }
    }
  }
}

#navbarResponsive {
  background-color: $gradient-head-end;
  .home & {
    background-color: $white;
  }
  text-align: center;
  padding: 0;
  z-index: 10;
  #menu-mobile-navigation {
    padding: $grid-gutter-width;
    .nav-item {
      .nav-link {
        text-transform: uppercase;
        text-decoration: none;
        font: $font-weight-bold $font-size-sm $font-family-sans;
        color: $white;
        padding: $grid-gutter-width*1.5 $grid-gutter-width/2;
        background: url(../images/border-mark.png) no-repeat 50% 0%;
        @include hover-focus {
          color: $brand-primary;
          .home & {
            color: $black;
          }
        }
      }
      &.active {
        .nav-link {
          color: $brand-primary;
        }
      }
      &:first-child {
        .nav-link {
          background-image: none;
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    &.collapse {
      display: none !important;
      &.show {
        display: block !important;
      }
    }
  }
}
