// Grid settings
/*
$main-lg-columns:         12;
$sidebar-lg-columns:      4;
*/
$grid-gutter-width-base:  20px;
$grid-gutter-width:       $grid-gutter-width-base;
$grid-gutter-height:      16px;


$blue:            #2E7DAB;
$blue-med:        #56A0D3;
$blue-light:      #6BB0DB;
$blue-dark:       #1c5f90;
$yellow:          #FDEFC5;
$yellow-50:       rgba($yellow,.5);
$yellow-25:       rgba($yellow,.25);
$yellow-20:       rgba($yellow,.2);
$white:           #fff;
$black:           #454545;
$beige:           #B8AD9B;
$red:             #FB473C;

$gradient-light-start:  $blue-light;
$gradient-light-end:    #ece8c8;

$gradient-head-start:   $blue-light;
$gradient-head-end:     #b4d0d0;

$brand-primary:   $blue;
$brand-secondary: $yellow;

$body-bg:         $white;
$body-color:      $black;

$link-color:            $blue-light;
$link-decoration:       underline;
$link-hover-color:      $blue;
$link-hover-decoration: underline;
$link-transition:       all .15s ease-in-out;

// Fonts
$font-family-serif:      'CentSchbook', Georgia, "Times New Roman", Times, serif;
$font-family-sans-serif: "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-sans:       $font-family-sans-serif;
$font-family-base:       $font-family-sans;

$font-weight-normal:  400;
$font-weight-base:    $font-weight-normal;
$font-weight-light:   300;
$font-weight-med:     600;
$font-weight-bold:    700;

$font-size-base: 1rem;         // 18px
$font-size-lg:   1.222222rem;  // 22px
$font-size-md:   .9444444rem;  // 17px
$font-size-sm:   .8888888rem;  // 16px
$font-size-xs:   .8333333rem;  // 15px
$font-size-2xs:  .7777777rem;  // 14px
$font-size-3xs:  .6666666rem;  // 12px
$font-size-4xs:  .6111111rem;  // 11px

$font-size-h1:   2.222222rem;  // 40px
$font-size-h2:   1.777777rem;  // 32px
$font-size-h3:   1.222222rem;  // 22px
$font-size-h4:   1.111111rem;  // 20px
$font-size-h5:   1rem;         // 18px
$font-size-h6:   .9444444rem;  // 17px

$line-base:              1.35;

$headings-font-family:   $font-family-serif;
$headings-font-weight:   $font-weight-base;
$headings-line-height:   1.2;
$headings-color:         inherit;


// Buttons
$btn-padding-x:                  1em;
$btn-padding-y:                  .65em;
$btn-line-height:                1;
$btn-font-weight:                $font-weight-med;
$btn-font-family:                $font-family-sans;
$btn-font-size:                  $font-size-2xs;
$btn-box-shadow:                 none;
$btn-focus-box-shadow:           none;
$btn-active-box-shadow:          none;

$btn-default-color:              $brand-primary;
$btn-default-bg:                 $brand-secondary;
$btn-default-color-hover:        $brand-secondary;
$btn-default-bg-hover:           $brand-primary;

$btn-primary-color:              $brand-primary;
$btn-primary-bg:                 $brand-secondary;
$btn-primary-color-hover:        $brand-secondary;
$btn-primary-bg-hover:           $brand-primary;

// Forms
$input-padding-x:                .8rem;
$input-padding-y:                .5rem;
$input-line-height:              1;

$input-bg:                       $white;
$input-bg-disabled:              $yellow-25;

$input-color:                    $black;
$input-border-color:             $blue-light;
$input-btn-border-width:         1px;
$input-box-shadow:               none;

$input-border-radius:            5px;
$input-border-radius-lg:         $input-border-radius;
$input-border-radius-sm:         $input-border-radius;

$input-bg-focus:                 $input-bg;
$input-border-focus:             $brand-primary;
$input-box-shadow-focus:         none;
$input-color-focus:              $input-color;

$input-color-placeholder:        rgba($black,.5);

$input-transition:               border-color ease-in-out .15s;
