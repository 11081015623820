/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/39e078");

@font-face {
  font-family: 'CentSchbook';
  src: url('../fonts/39E078_0_0.eot');
  src: url('../fonts/39E078_0_0.eot?#iefix') format('embedded-opentype'),
    url('../fonts/39E078_0_0.woff2') format('woff2'),
    url('../fonts/39E078_0_0.woff') format('woff'),
    url('../fonts/39E078_0_0.ttf') format('truetype');
  font-weight: normal;
	font-style: normal;
}

@font-face {
  font-family: 'CentSchbook';
  src: url('../fonts/39E078_1_0.eot');
  src: url('../fonts/39E078_1_0.eot?#iefix') format('embedded-opentype'),
    url('../fonts/39E078_1_0.woff2') format('woff2'),
    url('../fonts/39E078_1_0.woff') format('woff'),
    url('../fonts/39E078_1_0.ttf') format('truetype');
  font-weight: normal;
	font-style: italic;
}

@font-face {
  font-family: 'CentSchbook';
  src: url('../fonts/39E078_2_0.eot');
  src: url('../fonts/39E078_2_0.eot?#iefix') format('embedded-opentype'),
    url('../fonts/39E078_2_0.woff2') format('woff2'),
    url('../fonts/39E078_2_0.woff') format('woff'),
    url('../fonts/39E078_2_0.ttf') format('truetype');
  font-weight: bold;
	font-style: normal;
}

@font-face {
  font-family: 'CentSchbook';
  src: url('../fonts/39E078_3_0.eot');
  src: url('../fonts/39E078_3_0.eot?#iefix') format('embedded-opentype'),
    url('../fonts/39E078_3_0.woff2') format('woff2'),
    url('../fonts/39E078_3_0.woff') format('woff'),
    url('../fonts/39E078_3_0.ttf') format('truetype');
  font-weight: bold;
	font-style: italic;

}
