.hentry {
  .entry-content {
    .addtoany_shortcode {
      padding: $grid-gutter-width/2 0 $grid-gutter-width/3;
      border-top: 1px solid rgba($beige,.4);
      border-bottom: 1px solid rgba($beige,.4);
      display: inline-block;
      margin-bottom: $grid-gutter-width;
      a {
        display: inline-block;
        margin-right: 1rem;
      }
    }
  }
  .story-title {
    @extend .sans-light;
    font-size: $font-size-h3;
    color: $blue-light;
    line-height: 1.25;
    margin-bottom: $grid-gutter-width;
  }
}
.post-grid {
  .post-item {
    margin-bottom: $grid-gutter-width*2;
    @include media-breakpoint-up(md) {
      margin-bottom: $grid-gutter-width*3;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: $grid-gutter-width*4;
    }
    article {
      body:not(.post-type-archive-story) & {
        @extend .border-left-gradient;
      }
      height: 100%;
      .blog & {
        @include media-breakpoint-up(md) {
          padding-right: $grid-gutter-width*.5;
        }
        @include media-breakpoint-up(lg) {
          padding-right: $grid-gutter-width*.75;
        }
        @include media-breakpoint-up(xl) {
          padding-right: $grid-gutter-width;
        }
      }
      h2 {
        body:not(.post-type-archive-story) & {
          margin-bottom: $grid-gutter-width;
          @include media-breakpoint-down(sm) {
            font-size: $font-size-h3;
          }
          @include media-breakpoint-up(xl) {
            font-size: $font-size-h1;
          }
          a {
            @extend .gradient-text-link;
  /*
            .post-type-archive-conversation & {
              color: $black;
              text-decoration: none;
              @include hover-focus {
                color: $brand-primary;
              }
            }
  */
          }
        }
      }
      .post-type-archive-story & {
        h2 {
          @extend .sans-light;
          font-size: $font-size-h3;
          margin-left: 8px;
          margin-right: 8px;
          margin-bottom: $grid-gutter-width*.75;
          a {
            text-decoration: none;
            color: $blue-light;
            @include hover-focus {
              color: $brand-primary;
            }
          }
        }
        .btn {
          margin-left: 8px;
        }
      }
      .imgLink {
        display: inline-block;
        @extend .border-link-img;
      }
    }
  }
}
.wp-pagenavi {
	clear: both;
	margin: $grid-gutter-width 0 $grid-gutter-width*2;
	a,span {
  	text-decoration: none;
    font-size: $font-size-sm;
  	color:$black;
    border: 1px solid $brand-secondary;
  	padding:2px 7px;
  	margin: 2px 5px;
  	margin-left: 0;
  	&.previouspostslink,&.nextpostslink {
    	border-color: transparent;
      @include hover-focus {
        color: $brand-primary;
      }
  	}
  	&.previouspostslink {
    	padding-left: 0;
  	}
  	&.page {
    	background-color: $brand-secondary;
      @include hover-focus {
        background-color: $brand-primary;
        border-color: $brand-primary;
        color: $brand-secondary;
      }
  	}
  	&.current {
    	background-color: $white;
    	border-color: $brand-secondary;
    	font-weight: $font-weight-base;
  	}
	}
}
