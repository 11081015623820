/*
.gradient-vertical-light {
  @include gradient-y($gradient-light-start, $gradient-light-end, 0%, 100%);
}
.gradient-horizontal-light {
  @include gradient-x($gradient-light-start, $gradient-light-end, 0%, 100%);
}
*/
.list-normalize {
  margin: 0;
  padding: 0;
  list-style: none;
}
.sans-light {
  font-family: $font-family-sans;
  font-weight: $font-weight-light;
  letter-spacing: .02em;
}
.bg-yellow {
  background-color: $yellow;
}
.bg-yellow-50 {
  background-color: $yellow-50;
}
.bg-yellow-25 {
  background-color: $yellow-25;
}
.bg-yellow-20 {
  background-color: $yellow-20;
}
.bg-blue {
  background-color: $blue;
  color: $white;
}
.bg-blue-gradient {
  @include gradient-y($blue, $blue-dark, 0%, 100%);
  color: $white;
}
.gradient-text-link {
  color: $blue-dark;
  text-decoration: none;
/*
  background: -webkit-linear-gradient($blue, $blue-dark);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
*/
  @include hover-focus {
  color: $blue;
/*
    background: -webkit-linear-gradient($blue,$blue-light);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
*/
  }
}
.border-link-img {
  padding: 8px;
  background-color: $blue-med;
  border-radius: 8px;
  margin-bottom: $grid-gutter-width*.5;
  @include hover-focus {
    background-color: $blue;
  }
  img {
    border: none !important;
    margin: 0 !important;
    padding: 0 !important;
    border-radius: 2px;
    width: 100%;
    height: auto;
  }
}
.border-left-gradient {
  border: 0;
  border-left: 3px solid;
  border-image-source: linear-gradient(180deg, $gradient-light-start, $gradient-light-end);
  border-image-slice: 1;
  padding-bottom: $grid-gutter-width*.2;
  padding-left: $grid-gutter-width;

}
.title-decoration {
  color: $beige;
  font: $font-weight-bold $font-size-sm $font-family-sans;
  letter-spacing: .03em;
  text-transform: uppercase;
  padding-bottom: $grid-gutter-width*1.25;
  margin-bottom: $grid-gutter-width*1.25;
  background: url(../images/title-decoration.png) no-repeat 0 100%;
  background-size: 100px auto;
}
