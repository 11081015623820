.hero-home {
  min-height: 520px;
  padding: $grid-gutter-width*7 0 $grid-gutter-width*3.75;
  background-color: $blue-light;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  @include media-breakpoint-up(sm) {
    padding: $grid-gutter-width*7 0 $grid-gutter-width*3.75;
  }
  @include media-breakpoint-up(md) {
    min-height: 720px;
    padding: $grid-gutter-width*12 0 $grid-gutter-width*5;
  }
  @include media-breakpoint-up(lg) {
    padding: $grid-gutter-width*12 0 $grid-gutter-width*5.5;
  }
  article {
    @extend .border-left-gradient;
    position: relative;
    box-shadow: -$grid-gutter-width/2 $grid-gutter-width/2 $grid-gutter-width rgba(0, 0, 0, 0.15);
    border-top-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
    border-left-width: 10px;
    background-color: $white;
    padding: $grid-gutter-width*1.5 $grid-gutter-width*1.5 $grid-gutter-width*1.5 $grid-gutter-width;
    border-top-right-radius: $grid-gutter-width;
    border-bottom-right-radius: $grid-gutter-width;
    color: $brand-primary;
    font: $font-weight-light $font-size-md $font-family-sans;
    @include media-breakpoint-up(md) {
      font-size: $font-size-lg;
      border-left-width: 12px;
    }
    img {
      width: 100%;
      height: auto;
      max-width: 260px;
      @include media-breakpoint-up(md) {
        max-width: 340px;
      }
    }
    p {
      margin-bottom: $grid-gutter-width*.75;
    }
    small {
      display: block;
      color: $beige;
      font: $font-weight-med $font-size-4xs $font-family-sans;
      letter-spacing: .03em;
      text-transform: uppercase;
      @include media-breakpoint-up(md) {
        font-size: $font-size-2xs;
      }
    }
    img.decoration {
      position: absolute;
      width: 16px;
      height: auto;
      right: -16px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.block-home {
  padding-top: $grid-gutter-width*2;
  padding-bottom: $grid-gutter-width*2;
  @include media-breakpoint-up(md) {
    padding-top: $grid-gutter-width*3;
    padding-bottom: $grid-gutter-width*3;
  }
  @include media-breakpoint-up(lg) {
    padding-top: $grid-gutter-width*4;
    padding-bottom: $grid-gutter-width*4;
  }
  @include media-breakpoint-up(xl) {
    padding-top: $grid-gutter-width*5;
    padding-bottom: $grid-gutter-width*5;
  }
  .intro {
    font: $font-weight-light $font-size-h3 $font-family-sans;
    margin-bottom: $grid-gutter-width*1.5;
    color: $brand-primary;
    small {
      display: block;
      font: $font-weight-med $font-size-xs $font-family-sans;
    }
    @include media-breakpoint-up(md) {
      font-size: $font-size-h2;
      margin-bottom: $grid-gutter-width*3;
    }
  }
  .entries {
    article {
      @extend .border-left-gradient;
      margin-bottom: $grid-gutter-width*1.5;
      height: 100%;
      @include media-breakpoint-up(md) {
        margin-bottom: $grid-gutter-width*2.5;
      }
      h2 {
        @include media-breakpoint-down(sm) {
          font-size: $font-size-h3;
        }
        a {
          text-decoration: none;
        }
      }
    }
  }
  .viewall {
    margin: $grid-gutter-width*1.5 0 0;
    @include media-breakpoint-down(sm) {
      text-align: center;
    }
  }
  &.intro-block {
    @extend .bg-yellow-20;
    padding-top: $grid-gutter-width;
    padding-bottom: $grid-gutter-width;
    @include media-breakpoint-up(md) {
      padding-top: $grid-gutter-width*1.5;
      padding-bottom: $grid-gutter-width*1.5;
    }
    @include media-breakpoint-up(lg) {
      padding-top: $grid-gutter-width*2;
      padding-bottom: $grid-gutter-width*2;
    }
    @include media-breakpoint-up(xl) {
      padding-top: $grid-gutter-width*2.5;
      padding-bottom: $grid-gutter-width*2.5;
    }
    .intro {
      font-size: $font-size-lg;
      margin-bottom: 0;
    }
  }
  &.promo-block {
    background: $blue url(../images/bg-promo.png) repeat center center;
    color: $white;
    .row {
      align-items: center;
    }
    .image {
      figure {
        background: url(../images/promo-bracket-horizontal.png) no-repeat center bottom;
        background-size: 100% auto;
        padding: 0 0 $grid-gutter-width*1.5 0;
        margin: 0;
        @include media-breakpoint-up(md) {
          background: url(../images/promo-bracket-vertical.png) no-repeat right center;
          background-size: auto 100%;
          padding: 0 $grid-gutter-width*1.5 0 0;
        }
      }
    }
    .details {
      @include media-breakpoint-down(sm) {
        margin-top: $grid-gutter-width;
      }
      h2 {
        color: $white;
      }
      .btn {
        @include hover-focus {
          background-color: $white;
          color: $blue;
        }
      }
      p:last-child {
        margin-bottom: 0;
      }
    }
  }
  &.blogs {
    .entries {
      .entry {
        margin-bottom: $grid-gutter-width*1.5;
        @include media-breakpoint-up(md) {
          margin-bottom: $grid-gutter-width*2.5;
        }
        article {
          margin-bottom: 0;
          padding-bottom: $grid-gutter-width/2;
          h2 {
            a {
              @extend .gradient-text-link;
            }
          }
        }
      }
    }
  }
  &.stories {
    @extend .bg-yellow-20;
    .entries {
      .entry {
        margin-bottom: $grid-gutter-width*1.5;
        @include media-breakpoint-up(md) {
          margin-bottom: $grid-gutter-width*2.5;
        }
        h2 {
          font: $font-weight-light $font-size-h3 $font-family-sans;
          margin-left: 8px;
          margin-right: 8px;
          margin-bottom: $grid-gutter-width*.75;
          a {
            text-decoration: none;
            color: $blue-light;
            @include hover-focus {
              color: $brand-primary;
            }
          }
        }
        .btn {
          margin-left: 8px;
          margin-right: 8px;
        }
      }
    }
  }
  &.conversations {
    @extend .bg-blue-gradient;
    .strapline {
      color: $yellow;
      background-image: url(../images/title-decoration-light.png);
    }
    .intro {
      color: $white;
      small {
        color: $yellow;
      }
    }
    .entries {
      article {
        h2 {
          a {
            color: $white;
            text-decoration: none;
            @include hover-focus {
              color: $yellow;
            }
          }
        }
      }
    }
    .viewall {
      margin: $grid-gutter-width*4 0 0;
    }
  }
}
