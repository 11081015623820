footer.content-info {
  padding: $grid-gutter-width*2.5 0;
  text-align: center;
  .brand {
    display: inline-block;
    width: 180px;
    img {
      max-width: 100%;
    }
  }
}
